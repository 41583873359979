const values = {
  public: {
    api: {
      url: process.env.NEXT_PUBLIC_API_URL || "",
      growthBookUrl: process.env.NEXT_PUBLIC_GROWTHBOOK_URL || "",
    },
    base: { url: process.env.NEXT_PUBLIC_BASE_URL || "" },
  },
  env: process.env.NODE_ENV,
  api: {
    secretKey: process.env.API_SECRET_KEY || "",
  },
  amplitude: {
    apiKey: process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || "",
  },
  sender: {
    apiKey: process.env.SENDER_API_KEY || "",
  },
};

export default values;
