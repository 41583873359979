import "../styles/globals.css";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "config/theme";
import { ReactElement, ReactNode, useEffect } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { NextIntlProvider } from "next-intl";
import { AnimatePresence } from "framer-motion";
import { SessionProvider } from "next-auth/react";
import ErrorBoundary from "components/ErrorBoundary";
import { onError, getMessageFallback } from "services/i18n";
import { useAnalytics } from "services/analytics";
//import NProgress from "nprogress"; //nprogress module
//import "styles/nprogress.css"; //styles of nprogress
import Router from "next/router";
import "config/i18n";
import { FeatureFlagProvider } from "services/featureFlag";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

//const whiteListPath = (path: string) => {
//  const equal = ![
//    "/",
//    "/admin",
//    "/artists",
//    "/configure",
//    "/support",
//    "/pricing",
//    "/login",
//    "/sign-up",
//    "/terms",
//    "/privacy",
//  ].includes(path);
//  return equal;
//};
//Router.events.on("routeChangeStart", (e) => {
//  whiteListPath(e) && NProgress.start();
//});
//Router.events.on("routeChangeComplete", (e) => {
//  whiteListPath(e) && NProgress.done();
//});
//Router.events.on("routeChangeError", (e) => {
//  whiteListPath(e) && NProgress.done();
//});
//Router.events.on("routeChangeComplete", () => {
//  track(window?.location?.pathname);
//});
const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register(
        "/sw-font.js",
        {
          scope: "/",
        }
      );
      if (registration.installing) {
        console.log("Service worker installing");
      } else if (registration.waiting) {
        console.log("Service worker installed");
      } else if (registration.active) {
        console.log("Service worker active");
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

function MyApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  useAnalytics();
  useEffect(() => {
    registerServiceWorker();
  }, []);

  return (
    <NextIntlProvider
      messages={pageProps.messages || {}}
      onError={onError}
      getMessageFallback={getMessageFallback}
    >
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>
          <FeatureFlagProvider>
            <AnimatePresence exitBeforeEnter>
              <ErrorBoundary>
                {getLayout(<Component {...pageProps} />)}
              </ErrorBoundary>
            </AnimatePresence>
          </FeatureFlagProvider>
        </ChakraProvider>
      </SessionProvider>
    </NextIntlProvider>
  );
}

export default MyApp;
