import { extendTheme } from "@chakra-ui/react";
import { theme as ProTheme } from "config/theme/chakra-ui-pro-theme";

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const blackTheme = {
  "50": "#F2F2F2",
  "100": "#DBDBDB",
  "200": "#C4C4C4",
  "300": "#ADADAD",
  "400": "#969696",
  "500": "#000000",
  "600": "#666666",
  "700": "#4D4D4D",
  "800": "#333333",
  "900": "#1A1A1A",
};
const colors = { ...ProTheme.colors, brand: blackTheme };

const theme = extendTheme({ config, colors }, ProTheme);

export { theme };
