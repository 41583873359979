// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  BrowserClient,
  Breadcrumbs,
  Dedupe,
  defaultStackParser,
  getCurrentHub,
  GlobalHandlers,
  makeFetchTransport,
  LinkedErrors,
} from "@sentry/nextjs";
import config from "config";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const client = new BrowserClient({
  // all options you normally pass to Sentry.init
  environment: process.env.NODE_ENV,
  dsn:
    SENTRY_DSN ||
    "https://9724487c47de44d689a3c80c06e19f83@o1264047.ingest.sentry.io/6446282",
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  // Only the integrations listed here will be used
  integrations: [
    new Breadcrumbs(),
    new GlobalHandlers(),
    new LinkedErrors(),
    new Dedupe(),
  ],
});

process.env.NODE_ENV === "production" && getCurrentHub().bindClient(client);
