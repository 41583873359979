import { Component } from "react";
import Image from "next/image";
import { Center, Box, Text, Button } from "@chakra-ui/react";
import { withRouter, Router } from "next/router";

class ErrorBoundary extends Component<
  { router: Router; children: any },
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.log(error);
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // Manually log to an error service
    // Sentry automagically logs all unhandled exceptions
  }
  render() {
    const { hasError } = this.state;
    if (hasError) {
      return (
        <Center display="flex" flexDirection="column">
          <Box margin={4}>
            <Image src="/500.svg" width={350} height={350} alt="Empty" />
          </Box>
          <Text fontSize="xl">Oh no! Something went wrong.</Text>
          <Button margin={3} onClick={() => this.props.router.reload()}>
            Reload page
          </Button>
        </Center>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
