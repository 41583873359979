import { useEffect } from "react";
import {
  GrowthBook,
  GrowthBookProvider,
  useFeature as useGrowthBookFeature,
  IfFeatureEnabled,
} from "@growthbook/growthbook-react";
import type { Experiment, Result } from "@growthbook/growthbook";
import { useSession } from "next-auth/react";
import config from "config";

// Create a GrowthBook instance
const growthbook = new GrowthBook({
  trackingCallback: (
    experiment: Experiment<String>,
    result: Result<String>
  ) => {
    console.log({
      experimentId: experiment.key,
      variationId: result.variationId,
    });
  },
});

const useFeatureDefinitions = () => {
  useEffect(() => {
    // Load feature definitions from API
    fetch(config.public.api.growthBookUrl)
      .then((res) => res.json())
      .then((json) => {
        growthbook.setFeatures(json.features);
      });
  }, []);
};

interface Props {
  children: React.ReactNode;
}
const FeatureFlagProvider: React.FC<Props> = ({ children }) => {
  useFeatureDefinitions();
  return (
    <GrowthBookProvider growthbook={growthbook}>{children}</GrowthBookProvider>
  );
};

const EMPLOYEE_WHITELIST = [
  "test@test.test",
  "andrewnamt@gmail.com",
  "andrew@silkscan.xyz",
  "artjeju@artjejukorea.com",
  "sonya@artjejukorea.com",
];

const useSetAttributes = () => {
  const { status, data: session } = useSession();
  useEffect(() => {
    if (status !== "authenticated") return;

    const isEmployee = EMPLOYEE_WHITELIST.includes(session?.user?.email || "");
    growthbook.setAttributes({
      employee: isEmployee,
    });
  }, [status]);
};

const useFeature = (featureKey: string) => {
  useSetAttributes();
  const feature = useGrowthBookFeature(featureKey).on;
  return feature;
};

export { FeatureFlagProvider, useFeature, IfFeatureEnabled };
