import {
  init as amplitudeInit,
  track as aTrack,
  setUserId as amplitudeSetUserId,
} from "@amplitude/analytics-browser";
import config from "config";
import { useEffect } from "react";
import { useRouter } from "next/router";

interface TrackProperties {
  [key: string]: any;
}

const useAnalytics = () => {
  useEffect(() => {
    const init = async () => {
      await amplitudeInit(config.amplitude.apiKey).promise.then(() => {
        track(window?.location?.pathname);
      });
    };
    init().catch(console.error);
  }, []);
};

const setUserId = (userId?: string) => {
  if (!userId) return;

  amplitudeSetUserId(userId);
};

const useTrackPage = () => {
  const router = useRouter();
  useEffect(() => {
    track(router.pathname);
  });
};

const track = (label: string, options?: TrackProperties) => {
  aTrack(label, options || {});
};

export { track, useAnalytics, setUserId, useTrackPage };
